import React, { useCallback, useContext, useMemo } from "react"
import { useState, useEffect } from "react"
import {
	Button,
	Card,
	Col,
	Collapse,
	OverlayTrigger,
	Row,
	Tooltip,
	Dropdown,
	DropdownButton
} from "react-bootstrap"
import { nanoid } from "nanoid"
import { Nature_t, Liste_t } from "./apogee-types"
import { ErrorToast } from "../utilities/error-toast"
import { DetailsEtp } from "../syllabus/details-etp"
import { Details } from "../syllabus/details"
import { ListesAvecTabs, ListesSansTabs } from "./listes"
import { useSanctum } from "../sanctum/sanctum"
import { ContexteFormation } from "./formation"
//import { Edit } from "react-feather"

/**
 * ElementFull
 *
 * Objet de l'API issu de
 * backend-sagesse/app/Http/Controllers/ApogeeBotController.php (fonction element)
 */
interface ElementFull {
	code: string
	lic: string
	apoNat: string
	nbCrd: number
	nature: Nature_t
	warning: string | null
}

/**
 * Structure
 *
 * Objet de l'API issu de
 * backend-sagesse/app/Http/Controllers/ApogeeBotController.php (fonction element)
 */
interface Structure {
	element: ElementFull
	listes: Liste_t[]
}

interface Props {
	annee: number
	code: string
	nature: Nature_t
	antecedents?: { code: string; nature: Nature_t }[] // Liste des antécédents
}

export const Elp = ({ annee, code, nature, antecedents = [] }: Props): JSX.Element | null => {
	const updatedAntecedents = [...antecedents, { code, nature }]
	const { apiAccess } = useSanctum()
	const { langue, setLangue } = useContext(ContexteFormation)

	if (!setLangue) {
		throw new Error(
			"setLangue is undefined. Ensure ElpComponent is used within FormationProvider."
		)
	}
	const uniqId = useMemo(() => nanoid(), [])
	// useMemo(() => …, []) n’exécute la fonction nanoid() qu’une seule fois au montage,
	// et renvoie toujours la même valeur par la suite.

	const [ouvert, setOuvert] = useState<boolean>(false)
	const [data, setData] = useState<Structure | null>(null)

	const [erreur, setErreur] = useState<Error | null>(null) // n'esten fait  pas utilisé

	const fetchData = useCallback(async () => {
		const natureSA = nature.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
		const url = `/api/structure/${natureSA}/${annee}/${code}`
		try {
			const reponse = await apiAccess.get<Structure>(url)
			// console.log( { reponse })
			setData(reponse.data)
		} catch (error) {
			if (process.env.NODE_ENV === "development")
				console.error({ from: "Apogee.Elp.fetch", error })
		}
	}, [annee, apiAccess, code, nature])

	// s'exécute au montage
	useEffect(() => {
		fetchData()
	}, [fetchData])

	if (data == null) return null

	let border = null
	switch (nature) {
		case "période":
		case "periode":
			border = "primary"
			break
		case "module":
			border = "danger"
			break
		case "matière":
			border = "success"
			break
		default:
			border = "dark"
	}

	const listesOSeulement: boolean = data?.listes?.every((l) => l.typLse === "O")
	// const typeListes: string = data?.listes?.some((l) => l.typLse === "X")
	// 	? "X"
	// 	: data?.listes?.some((l) => l.typLse === "F")
	// 		? "F"
	// 		: "O"

	const bouton = (
		<Button
			variant={ouvert ? "outline-danger" : "outline-info"}
			size="sm"
			className="col-auto"
			onClick={() => setOuvert(!ouvert)}
			aria-expanded={ouvert}
			aria-controls={"detailsElement-" + uniqId}
		>
			{ouvert ? "Fermer" : "Détails"}
		</Button>
	)

	// Fonction pour filtrer et construire les codes des antécédents dans l'ordre
	const getOrderedAntecedents = () => {
		const typesOrder = ["étape", "periode", "module", "matière"]
		const orderedAntecedents: string[] = []
		for (const type of typesOrder) {
			const antecedent = updatedAntecedents.find((ant) => ant.nature === type)
			if (!antecedent) break // Arrête le traitement si un type est absent
			orderedAntecedents.push(antecedent.code)
		}
		return orderedAntecedents.join("/") // Concatène les codes avec '/'
	}

	const ouvrirImprimer = (): void => {
		const url = `../${langue === "en" ? "printable" : "imprimable"}/${annee}/${getOrderedAntecedents()}`
		// Paramètres de la fenêtre
		const parametres =
			"toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600"
		// Ouvre la nouvelle fenêtre
		window.open(url, "", parametres)
	}

	const tooltip = (
		<Tooltip id={"tooltip-" + uniqId}>
			{langue === "fr"
				? "Affiche une version imprimable de cet élément, ses ascendants et ses descendants, dans une nouvelle fenêtre."
				: "Displays a printable version of this item, its ancestors, and its descendants, in a new window."}
		</Tooltip>
	)

	const imprimer = (
		<Col xs="auto" className="d-flex align-items-center gap-2">
			{/* Menu déroulant pour choisir la langue */}
			<DropdownButton
				id={`dropdown-${uniqId}`}
				title={langue}
				size="sm"
				variant="outline-secondary"
				onSelect={(eventKey) => setLangue(eventKey || "fr")}
			>
				<Dropdown.Item eventKey="fr">fr</Dropdown.Item>
				<Dropdown.Item eventKey="en">en</Dropdown.Item>
			</DropdownButton>
			<OverlayTrigger placement="top" overlay={tooltip}>
				<Button
					variant="outline-primary"
					size="sm"
					className="col-auto"
					onClick={ouvrirImprimer}
				>
					{langue === "fr" ? "Imprimable" : "Printable"}
				</Button>
			</OverlayTrigger>
		</Col>
	)

	const etiquette = (
		<Col xs="auto">
			{data.element.nature} ({data.element.apoNat}) {data.element.code}: {data.element.lic} ;{" "}
			{data.element.nature === "matière"
				? "coefficient " + data.element.nbCrd
				: data.element.nbCrd + " crédits."}
		</Col>
	)

	const details = (
		<Collapse in={ouvert}>
			<div id={"detailsElement-" + uniqId}>
				<Row className="mt-2">
					{nature === "étape" || nature === "etape" ? (
						<DetailsEtp annee={annee} code={code} ouvert={ouvert} />
					) : (
						<Details
							annee={annee}
							terminal={data.listes.length === 0}
							nature={nature}
							code={code}
							ouvert={ouvert}
						/>
					)}
				</Row>
				<Row className="mt-2">
					{bouton} {etiquette} {/*edit*/}{" "}
				</Row>
			</div>
		</Collapse>
	)

	return (
		<Card border={border} className="border-5">
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			{/* <Card.Header>
				{data.element.code} - {data.element.nature}
				<div>
					<strong>Antécédents :</strong>{" "}
					{updatedAntecedents.map((ant, idx) => (
						<span key={idx}>
							{ant.code} ({ant.nature}){idx < updatedAntecedents.length - 1 && " → "}
						</span>
					))}
				</div>
			</Card.Header> */}
			<Card.Header className="container-fluid">
				<Row className="justify-content-between">
					{" "}
					{bouton} {etiquette} {/*ouvert && edit*/} {imprimer}
				</Row>
				{data.listes.length > 0 && details}
			</Card.Header>

			{data.listes.length === 0 ? (
				<Card.Body> {details} </Card.Body>
			) : data.listes.length === 1 && listesOSeulement ? (
				<ListesSansTabs
					listes={data.listes}
					annee={annee}
					antecedents={updatedAntecedents}
				/>
			) : (
				<ListesAvecTabs
					listes={data.listes}
					annee={annee}
					antecedents={updatedAntecedents}
				/>
			)}
		</Card>
	)
}
