import React, { useCallback } from "react"
import { set } from "lodash"
import { useState, useEffect, useContext } from "react"
import { ApogeeElp_t } from "../apogee/apogee-types"
import { ErrorToast } from "../utilities/error-toast"
import { TexteBilingue } from "../utilities/bilingue"
import { ContexteDetails } from "./details"
import {
	DescElpRead_t,
	DescElpWrite_t,
	NoticeElp_t,
	NoticeElp_tw
} from "./syllabus-types"
import { Bilingue_t } from "../utilities/bilingue"
import { useSanctum } from "../sanctum/sanctum"
import { Card, Row } from "react-bootstrap"

/**
 * Notice
 *
 * Objet React de manipulation (affichage/édition) de la notice descriptive d'un élément
 *
 * @returns JSX.Element | null
 */
export const Notice = (): JSX.Element | null => {
	const {
		apiAccess,
		authState: { user }
	} = useSanctum()

	const [apogee, setApogee] = useState<ApogeeElp_t | null>(null)
	const [notice, setNotice] = useState<NoticeElp_t | null>(null)
	const [erreur, setErreur] = useState<Error | null>(null)
	const ctxt = useContext(ContexteDetails)

	/**
	 * fetchDescription
	 *
	 * fonction asynchrone d'écriture/lecture de la notice
	 *
	 * Une charge nulle à l'écriture sur une partie de la description
	 * déclenche la lecture de cete partie. Une charge non nulle déclenche
	 * l'écriture PUIS la lecture.
	 *
	 * @param valeur : DescElpWrite_t
	 */
	const fetchDescription = useCallback(
		async (valeur: DescElpWrite_t): Promise<void> => {
			try {
				const reponse = await apiAccess.post<DescElpRead_t>(
					ctxt.url,
					valeur
				)
				if (reponse.data.apogee !== undefined)
					setApogee(reponse.data.apogee)
				if (reponse.data.notice !== undefined)
					setNotice(reponse.data.notice)
			} catch (err: unknown) {
				if (err instanceof Error) setErreur(err)
			}
		},
		[apiAccess, ctxt.url]
	)

	useEffect(() => {
		fetchDescription({ notice: null, apogee: null })
	}, [fetchDescription])

	// useEffect(() => console.log({ apogee, notice }))

	if (apogee == null) return null

	const baseNotice: NoticeElp_tw = {
		//codElp: apogee.cod_elp,
		//validite: `[${ctxt.annee},)`,
		description: null,
		prerequis: null,
		mcc: null
	}

	/**
	 * updateFn
	 *
	 * Générateur de callback de mise à jour
	 *
	 * @param key
	 * @returns
	 */
	function updateFn<Type>(key: string) {
		return (value: Type) => {
			const notice: NoticeElp_tw = set(
				Object.assign({}, baseNotice),
				key,
				value
			)
			fetchDescription({ notice })
		}
	}

	const coeur = (): JSX.Element => (
		<Row>
			<ErrorToast erreur={erreur} onDismiss={setErreur} />
			<TexteBilingue
				richText
				titre="Contexte et objectifs"
				tip="Contexte, objectifs et domaine d'application de l'enseignement"
				texte={notice?.description?.contexte ?? { fr: "" }}
				header={ctxt.header}
				update={updateFn<Bilingue_t>("description.contexte")}
			/>
			<TexteBilingue
				richText
				titre="Contenu"
				tip={
					"Texte plus ou moins détaillé décrivant le contenu et," +
					" éventuellement, le déroulement des enseignements."
				}
				texte={notice?.description?.contenu ?? { fr: "" }}
				header={ctxt.header}
				update={updateFn<Bilingue_t>("description.contenu")}
			/>
			<TexteBilingue
				richText
				titre="Ressources"
				tip={
					"Texte mentionnant en particulier les livres conseillés," +
					" l’existence de polycopiés et autres documents électroniques" +
					" à disposition des élèves, l’utilisation de plateformes LMS" +
					" (Moodle, Wims, ...), en précisant le nom du cours et son" +
					" chemin d’accès. On pourra mentionner aussi l’existence de" +
					" salles en libre service (éventuellement sur des créneaux" +
					" horaires définis) permettant de réaliser des travaux pratiques." +
					" On pourra aussi mentionner l’existence de clubs d’élèves" +
					" (Robotech, Polybière, etc.) si leur activité peut constituer" +
					" une mise en pratique d’acquis d'apprentissage de l'élement" +
					" de programme considéré."
				}
				texte={notice?.description?.ressources ?? { fr: "" }}
				header={ctxt.header}
				update={updateFn<Bilingue_t>("description.ressources")}
			/>
			<TexteBilingue
				richText
				titre="Prérequis"
				tip={"Texte décrivant les enseignements prérequis."}
				texte={notice?.prerequis?.text ?? { fr: "" }}
				header={ctxt.header}
				update={updateFn<Bilingue_t>("prerequis.text")}
			/>
			{/*
			Manque ici l'objet d'affichage/saisie des prérequis sous forme de liens à des éléments.
			leur définition: liste: notice?.prerequis?.liste ?? []
			Affichage:
				{ prerequis?.liste &&
					<Boite titre="Prérequis:liste">
						<ul>{prerequis.liste.map((elt, index) => <li key={index}>{elt}</li>)}</ul>
					</Boite>
				}
		*/}
			<TexteBilingue
				richText
				titre="Modalités de contrôle des connaissances"
				// prettier-ignore
				tip={
					"Texte précisant le nombre d’examens, l’existence ou non" +
					" d’un contrôle continu (compte rendu de TP par exemple), etc." +
					" Décrit aussi la façon dont toutes ces notes sont \"agglomérées\"" +
					" pour former la note affectée à l'élément de programme."
				}
				texte={notice?.mcc?.text ?? { fr: "" }}
				header={ctxt.header}
				update={updateFn<Bilingue_t>("mcc.text")}
			/>
		</Row>
	)

	// Liste d'éléments pour prérequis mise en attente
	// (demandera sûrement de modifier TexteBilingue...)
	// De même pour les mcc_dfe
	// mis en attente
	return user ? (
		<Card className="m-1">
			<Card.Header>
				<span className="text-primary h4">Notice</span>
				{notice && notice.updated_by && (
					<span className="text-info small">
						&nbsp;(éditée{" "}
						{(() => {
							const date = new Date(notice.updated_at + "Z")
							return (
								`à ${date.toLocaleTimeString("fr-FR")} le ` +
								date.toLocaleDateString("fr-FR") +
								" par " +
								notice.updated_by
							)
						})()}
						)
					</span>
				)}
			</Card.Header>
			<Card.Body className="m-1 p-0 mt-2">{coeur()}</Card.Body>
		</Card>
	) : (
		coeur()
	)
}
