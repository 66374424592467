import React from "react"
import Markdown from "markdown-to-jsx"
import { Table } from "react-bootstrap"

interface MarkdownRendererProps {
	content: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {
	return (
		<Markdown
			options={{
				overrides: {
					h1: { // eslint-disable-next-line
						component: (props) => <h1 className="texteSyllabus h1" {...props} />,
					},
					h2: { // eslint-disable-next-line
						component: (props) => <h2 className="texteSyllabus h2" {...props} />,
					},
					h3: { // eslint-disable-next-line
						component: (props) => <h3 className="texteSyllabus h3" {...props} />,
					},
					h4: { // eslint-disable-next-line
						component: (props) => <h4 className="texteSyllabus h4" {...props} />,
					},
					h5: { // eslint-disable-next-line
						component: (props) => <h5 className="texteSyllabus h5" {...props} />,
					},
					h6: { // eslint-disable-next-line
						component: (props) => <h6 className="texteSyllabus h6" {...props} />,
					},
					p: {
						component: (props) => <p className="texteSyllabus" {...props} />,
					},
					ul: {
						component: (props) => <ul className="texteSyllabus list-disc pl-6" {...props} />,
					},
					ol: {
						component: (props) => <ol className="texteSyllabus list-decimal pl-6" {...props} />,
					},
					li: {
						component: (props) => <li className="mb-2" {...props} />,
					},
					a: { 
						component: (props) => ( // eslint-disable-next-line
							<a
								className="link-primary"
								target="_blank"
								rel="noopener noreferrer"
								{...props}
							/>
						),
					},
					blockquote: {
						component: (props) => (
							<blockquote className="pl-4 border-l-4 border-gray-400 italic" {...props} />
						),
					},
					code: {
						component: (props) => (
							<code className="bg-light p-1 rounded" {...props} />
						),
					},
					table: {
						component: (props) => (
							<Table striped bordered size="sm" {...props} />
						),
					},
				},
			}}
		>
			{content}
		</Markdown>
	)
}

export default MarkdownRenderer
